@import "styles/config";
$accentLight: #d8e074;
$accentLightAlt: #fad433;
$accentDark: #1b5e20;
$baseWhite: #ffffff;
$box-shadow: 0px 0px 20px $accentLightAlt;
$background-gray: #f4f4f4;

%location {
  .location {
    &-container {
      &--header {
        & .body {
          padding: 2rem 1rem;
          &-art {
            height: 60px !important;
            right: 2rem !important;
          }
        }
        & .tabs {
          &-container {
            padding: 1rem 2rem 0 2rem;
          }
        }
      }
    }
  }
}

%locationCard {
  @include elevation(2);

  // hide 'Pickup Time: 20 minutes' span
  .address {
    > div {
      span:last-of-type {
        display: none;
      }
    }
  }
}

.rc-tabs-bar {
  background-color: $accentLight;
  padding: 1rem;
}

.rc-tabs-tab {
  text-align: center;
  margin: 0 3.75rem;
  right: 2.813rem;

  &-active {
    color: transparent;
  }
}

%menuGroup {
  .group {
    border-bottom: 1.5px solid $accentLightAlt;
  }
}

%menuItem {
  box-shadow: 0px 0px 7px 2px #ececec !important;
  transition: all 0.5s ease-in-out;
  &:hover {
    box-shadow: $box-shadow !important;
  }
}

// special styling for navbar in itemDetails
%itemDetails-tabs {
  .itemDetails-tabs {
    &-nav-container {
      .nav {
        background-color: $accentLight !important;
        .nav-item {
          .item-button {
            padding: 0.5rem 1rem;
          }
          &[data-active="1"] {
            .item-button {
              background-color: $accentDark !important;
              span {
                color: white !important;
              }
            }
          }
        }
      }
    }
  }
}

%itemDetails {
  .itemDetails-back {
    & img {
      width: 2rem;
      height: 2rem;
      position: relative;
      right: 0.5rem;
    }
    & div {
      width: 1rem !important;
    }
  }
}

/// sets a max width to the header info component.
%locationInfo {
  max-width: 30rem !important;
  .locationInfo-body {
    line-height: 1.5;
  }
}

// Center tip selector
.tip {
  & > span {
    display: flex !important;
    align-items: center !important;

    .tip-label {
      margin-bottom: 0 !important;
    }

    .tip-group {
      flex-grow: 1 !important;
      display: flex !important;
      justify-content: center !important;
    }
  }
}

%loyaltyHeader {
  padding: 0 0 1rem 0 !important;
  .loyaltyHeader {
    &-description {
      &-text {
        text-align: left;
        margin-left: 1rem;
        max-width: 20rem;
      }
    }
  }
}
