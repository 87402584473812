:root {
  --font-1: "GoldanaScript";
  --font-2: "BeirutDisplay-SemiBold";
  --font-3: "campton-book";
  --font-4: "campton-bold";
}

@font-face {
  font-family: GoldanaScript;
  src: url("../../clients/#{$client}/fonts/GoldanaScript.otf") format("opentype");
}

@font-face {
  font-family: BeirutDisplay-SemiBold;
  src: url("../../clients/#{$client}/fonts/BeirutDisplay-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: campton-book;
  src: url("../../clients/#{$client}/fonts/campton-book.otf") format("opentype");
}

@font-face {
  font-family: campton-bold;
  src: url("../../clients/#{$client}/fonts/campton-bold.otf") format("opentype");
}
